<template>
  <b-card>
    <b-card-title class="card-title mx-2 mt-2">
      <b-row class="row">
        <b-col cols="7">
          <button
            class="btn btn-sm button-primary mr-2"
            @click="onAdd"
            v-if="isAdminOrStaff"
          >
            <feather-icon
            size="15"
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span style="font-size: 15px">Agregar Punto</span>
          </button>

          <button class="btn btn-sm btn-primary" @click="onXml">
            <feather-icon
              size="15"
              icon="DownloadIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">Descargar XLS</span>
          </button>

          <router-link
            class="btn btn-sm button-tertiary text-white ml-2"
            to="/dashboard/points/upload"
            title="Actualizar"
          >
            <feather-icon
              size="15"
              icon="UploadIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">Subir XLS</span>
          </router-link>
        </b-col>
        <b-col cols="5">
          <filter-bar-component v-on:search="onSearch"></filter-bar-component>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <full-table-component
        ref="fulltable"
        :fields="fields"
        :perPage="100"
        url="/points"
      >
        <template slot="google-slot" slot-scope="props">
          <a
            :href="
              'https://www.google.com/maps?q=' +
              props.rowData.latitude +
              ',' +
              props.rowData.longitude
            "
            target="_blank"
            class="pl-2"
            v-if="props.rowData.latitude"
          >
            <feather-icon
              size="20"
              icon="MapPinIcon"
              class="mr-50"
            />
          </a>

          <a class="pl-2 text-muted" v-else>
            <feather-icon
              size="20"
              icon="MapPinIcon"
              class="mr-50"
            />
          </a>
        </template>

        <template slot="buttons-slot" slot-scope="props">
          <b-dropdown
            class="p-0"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                size="20"
                icon="ListIcon"
              />
            </template>
            <b-dropdown-item @click="onView(props.rowData)">
              <feather-icon
                size="20"
                icon="SearchIcon"
              />
              Ver detalles
            </b-dropdown-item>
            <b-dropdown-item
              @click="onEdit(props.rowData)"
              v-if="isAdminOrStaff"
            >
              <feather-icon
                size="20"
                icon="Edit3Icon"
              />
              Editar
            </b-dropdown-item>
            <b-dropdown-item
              @click="onDelete(props.rowData)"
              v-if="isAdminOrStaff"
            >
              <feather-icon
                size="20"
                icon="TrashIcon"
              />
              Eliminar
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </full-table-component>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import FullTableComponent from "@/layouts/components/FullTableComponent";
import FilterBarComponent from "@/layouts/components/FilterBarComponent";

import moment from "moment";

import PointService from "@/services/PointService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";

import PointEdit from "./PointEdit";
import PointView from "./PointView";

export default {
  name: "PointAll",
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BCardBody,
    BDropdown,
    BDropdownItem,
    FilterBarComponent,
    FullTableComponent,
  },
  data() {
    return {
      fields: [
        {
          name: "id",
          title: "Id",
          sortField: "id",
        },
        {
          name: "created_at",
          title: "Creado",
          formatter: (value) => {
            return UtilService.date(value);
          },
        },
        {
          name: "location",
          title: "Localidad",
          sortField: "location",
        },
        {
          name: "province",
          title: "Provincia",
          sortField: "province",
        },
        {
          name: "height",
          title: "Altura",
          sortField: "height",
        },
        {
          name: "street",
          title: "Calle",
          sortField: "street",
          width: "5%"
        },
        {
          name: "postal_code",
          title: "Código Postal",
          sortField: "postal_code",
        },
        {
          name: "google-slot",
          title: "Mapa",
          sortField: "latitude",
        },

        {
          name: "buttons-slot",
          title: "Acciones",
          titleClass: "text-right",
          dataClass: "text-right",
        },
      ],
      isAdminOrStaff: UserService.isAdminOrStaff(),
    };
  },
  methods: {
    /**
     *
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },

    /**
     *
     */
    add() {
      this.$refs["edit-point"].create();
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar el punto con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        PointService.destroy(item)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    async onAdd() {
      this.$modal.show(
        PointEdit,
        {
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onView(item) {
      this.$modal.show(
        PointView,
        {
          id: item.id,
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onEdit(item) {
      this.$modal.show(
        PointEdit,
        {
          id: item.id,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onXml() {
      this.isLoading = true;
      const search = this.$refs.search ? this.$refs.search.filterText : "";
      PointService.export({
        search: search,
      })
        .then((response) => {
          window.open(process.env.VUE_APP_API + '/operation_export/' + response.data.id, "_blank");
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (!UserService.isAdminOrStaffOrReseller()) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.card-body{
  padding: 0 !important;
}
</style>
